export enum Right {
    // Legal entity
    LEGAL_ENTITY_APPROVAL = 'LEGAL_ENTITY_APPROVAL',
    LEGAL_ENTITY_APPROVED_U = 'LEGAL_ENTITY_APPROVED_U',
    LEGAL_ENTITY_DRAFT_C = 'LEGAL_ENTITY_DRAFT_C',
    LEGAL_ENTITY_DRAFT_U = 'LEGAL_ENTITY_DRAFT_U',
    REQUEST_LEGAL_ENTITY_APPROVED_U = 'REQUEST_LEGAL_ENTITY_APPROVED_U',

    // Licence contract
    LICENCE_CONTRACT_ACTIVE_U = 'LICENCE_CONTRACT_ACTIVE_U',
    LICENCE_CONTRACT_CHANGE_STATUS = 'LICENCE_CONTRACT_CHANGE_STATUS',
    LICENCE_CONTRACT_DRAFT_C = 'LICENCE_CONTRACT_DRAFT_C',
    LICENCE_CONTRACT_DRAFT_D = 'LICENCE_CONTRACT_DRAFT_D',
    LICENCE_CONTRACT_DRAFT_U = 'LICENCE_CONTRACT_DRAFT_U',
    LICENCE_CONTRACT_FEE_R = 'LICENCE_CONTRACT_FEE_R',
    LICENCE_CONTRACT_TRANSFERNOTE_R = 'LICENCE_CONTRACT_TRANSFERNOTE_R',
    REQUEST_LICENCE_CONTRACT_APPROVAL = 'REQUEST_LICENCE_CONTRACT_APPROVAL',

    // Licence contract child entities
    ACADEMY_INFO_R = 'ACADEMY_INFO_R',
    GG_AND_ACADEMY_INFO_U = 'GG_AND_ACADEMY_INFO_U',
    GG_INFO_R = 'GG_INFO_R',
    LICENCE_CONTRACT_ACTIVE_CONTACT_U = 'LICENCE_CONTRACT_ACTIVE_CONTACT_U',
    LICENCE_CONTRACT_ACTIVE_EXTENSION_U = 'LICENCE_CONTRACT_ACTIVE_EXTENSION_U',
    LICENCE_CONTRACT_ACTIVE_FEE_U = 'LICENCE_CONTRACT_ACTIVE_FEE_U',
    LICENCE_CONTRACT_DRAFT_CONTACT_U = 'LICENCE_CONTRACT_DRAFT_CONTACT_U',
    LICENCE_CONTRACT_DRAFT_EXTENSION_U = 'LICENCE_CONTRACT_DRAFT_EXTENSION_U',
    LICENCE_CONTRACT_DRAFT_FEE_U = 'LICENCE_CONTRACT_DRAFT_FEE_U',

    // Licence area
    LICENCE_AREA_DELETE = 'LICENCE_AREA_DELETE',
    LICENCE_AREA_DOWNLOAD = 'LICENCE_AREA_DOWNLOAD',
    LICENCE_AREA_UPLOAD = 'LICENCE_AREA_UPLOAD',

    // Shop
    REQUEST_PLANNED_SHOP = 'REQUEST_PLANNED_SHOP',
    SHOP_C = 'SHOP_C',
    SHOP_DRAFT_C = 'SHOP_DRAFT_C',
    SHOP_DRAFT_D = 'SHOP_DRAFT_D',
    SHOP_DRAFT_U = 'SHOP_DRAFT_U',
    SHOP_MOVE_BETWEEN_LICENCE_AREAS = 'SHOP_MOVE_BETWEEN_LICENCE_AREAS',
    SHOP_OPENED_CLOSE = 'SHOP_OPENED_CLOSE',
    SHOP_OPENED_U = 'SHOP_OPENED_U',
    SHOP_PLANNED_C = 'SHOP_PLANNED_C',
    SHOP_PLANNED_CLOSE = 'SHOP_PLANNED_CLOSE',
    SHOP_PLANNED_OPEN = 'SHOP_PLANNED_OPEN',
    SHOP_PLANNED_U = 'SHOP_PLANNED_U',

    // Sub-shop
    PROPERTY_LOUNGE_C = 'PROPERTY_LOUNGE_C',
    PROPERTY_LOUNGE_U = 'PROPERTY_LOUNGE_U',
    SUB_SHOP_CLOSE = 'SUB_SHOP_CLOSE',
}
